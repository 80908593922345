// ======================================================================
// Forge Lite | hello@hashandsalt.com
// ======================================================================

// window.$ = window.jQuery = require('jquery');

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');


// ======================================================================
// Accordion Helper
// ======================================================================

document.addEventListener("DOMContentLoaded", function(){

var acc = document.getElementsByClassName("_accordion-button"),
i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    var parent = this.closest("._accordion-container"),
    sibling = this.nextElementSibling;
    if (sibling.classList.contains("_accordion-toggle")){
      sibling.style.setProperty('--max-height', sibling.scrollHeight + 'px');
    }
    var carousel = sibling.querySelector('.accordion-carousel');
    if (carousel){ initFlickity(carousel) } // initiate Flickity 
    parent.classList.toggle("active");
    if (this.classList.contains("_inject-slug")){
      injectSlug(parent);
    }
  });
}

function injectSlug(item){
  var itemID = item.id;
  newHash = "#" + itemID;
  window.location.hash = newHash;
}


// ======================================================================
// Person-Grid Helper
// ======================================================================

var items = document.getElementsByClassName("grid-item--button"),
itemcontainers = document.getElementsByClassName("grid-item-container"),
popups = document.getElementsByClassName("grid-popup"),
closer = document.getElementsByClassName("popup-close"),
i;

window.addEventListener('resize', popupresizeEvent);
setPopupType()

function setPopupType(){
  if (window.innerWidth >= 640){
    for (i = 0; i < itemcontainers.length; i++) {
      itemcontainers[i].classList.remove("mobile-toggle")
      itemcontainers[i].classList.remove("active")
    }
  } if (window.innerWidth < 640){
    for (i = 0; i < itemcontainers.length; i++) {
      itemcontainers[i].classList.add("mobile-toggle")
    }
  }
}

function popupresizeEvent(){
  setPopupType()
}

function removeActive(){
  for (i = 0; i < popups.length; i++) {
    popups[i].classList.remove("active")
  }
}

for (i = 0; i < items.length; i++) {
  items[i].addEventListener("click", function() {
    if (this.classList.contains("mobile-toggle")){
      this.classList.toggle("active");
    } else {
      removeActive();
      var itemId = this.id
      var selector = ".grid-popup#" + itemId
      var popup = document.querySelector(selector)
      popup.classList.toggle("active");
    }
  });
}

for (i = 0; i < closer.length; i++) {
  closer[i].addEventListener("click", function() {
    this.closest(".grid-popup").classList.toggle("active");
  });
}

// for (i = 0; i < mobileToggle.length; i++) {
//   if (window.innerWidth < 640){
//     mobileToggle[i].addEventListener("click", function(e) {
//       console.log("click")

//       e.stopPropagation();
//       //this.classList.toggle("active");
//     });
//   }
// }

// ======================================================================
// Flickity Slider 
// ======================================================================

var galleryElems = document.querySelectorAll('.main-carousel');
var carouselNavElems = document.querySelectorAll('.carousel-nav-item');

function removeSelected(){
  for (i = 0; i < carouselNavElems.length; i++) {
    carouselNavElems[i].classList.remove("is-selected")
  }
}

for ( var i=0, len = galleryElems.length; i < len; i++ ) {
  var galleryElem = galleryElems[i];
  galleryElem.addEventListener("click", function() {
    var itemId = '#' + this.id + ' .carousel-item.is-selected',
    selectedItemId = document.querySelector(itemId).id,
    findElm = '.carousel-nav-item#' + selectedItemId,
    navElm = document.querySelector(findElm);
    removeSelected();
    navElm.classList.add("is-selected");
  });
  initFlickity(galleryElem)
}

function initFlickity(el){
  new Flickity( el, {
    cellAlign: 'center',
    adaptiveHeight: true,
    lazyLoad: true,
    pageDots: false,
    draggable: false,
    accessibility: false,
    imagesLoaded: true,
    contain: true

  });
}


// ======================================================================
// Sticky Header
// ======================================================================

// When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Add event listener on window resize
window.addEventListener('resize', resizeEvent);

var stickyHeader = document.querySelector(".sticky-elm"),
header = document.querySelector(".header"),
headerButton = document.querySelector(".header-button"),
headerLinks = document.querySelectorAll(".header .link-container a"),
sticky = stickyHeader.offsetTop;

function myFunction() {
  if (window.pageYOffset > sticky) {
    stickyHeader.classList.add("sticky");
  } else {
    stickyHeader.classList.remove("sticky");
  }
}

// Apply Active Styles to Anchors Links

// Get Location Hash
function getLocationHash(){
  var hash = document.location.hash;
  if (hash) {
    let el = document.querySelector(hash),
    carousel = document.querySelector(hash + " .accordion-carousel"),
    headerLink = document.querySelector(hash + "_link");
    parentID = document.querySelector("#" + el.parentNode.id + "_link");
    console.log(parentID, el.parentNode.id)
    if (carousel){ initFlickity(carousel) } // initiate Flickity 
    if (headerLink){ headerLink.classList.add("active"); }
    if (parentID){ parentID.classList.add("active"); }
    el.classList.add("active");
  }
}

getLocationHash()

// Apply Mobile Header Transition Animation
headerButton.addEventListener("click", function() {
  if (window.innerWidth < 640){
    header.classList.toggle("active");
    header.classList.add("transition");
  }
})

// Prevent Menu Toggle on Header Links
for (i = 0; i < headerLinks.length; i++) {
  if (window.innerWidth < 640){
    headerLinks[i].addEventListener("click", function(e) {
      e.stopPropagation();
    });
  }
}

// Remove Transition on Desktop Site
function resizeEvent(){
  if (window.innerWidth >= 640){
    header.classList.remove("transition");
  }
}

// ======================================================================
// Share Button
// ======================================================================

shareButtons = document.querySelectorAll("._event-share-button");

for (i = 0; i < shareButtons.length; i++) {
  shareButtons[i].addEventListener("click", function(e) {
    let elm = this,
    dataID = this.getAttribute("data-id"),
    copyText = document.querySelector('input[data-id=' + dataID + ']');
  
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Reveal Copy Success Text */
    elm.classList.add("active");

    /* Remove Copy Success Text */
    setTimeout(function(){ 
      elm.classList.remove("active")
    }, 2000);

  });
}


}); // Close DOMContentLoaded Event Listener